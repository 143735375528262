import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import PageNotFoundIllustration from "../images/page_not_found_illustration.svg"

export interface FourOFourErrorProps {}

const FourOFourError: React.SFC<FourOFourErrorProps> = () => {
  return (
    <Layout>
      <div className="w-2/3 mx-auto">
        <img
          src={PageNotFoundIllustration}
          alt="Seite nicht gefunden Illustration"
          title="Seite nicht gefunden Illustration"
        />
        <div className="text-center my-20">
          <h1 className="text-5xl mb-6 leading-tight font-semibold font-heading">
            404 Seite nicht gefunden
          </h1>
          <Link
            to="/"
            className="inline-block py-4 px-8 mr-6 leading-none text-white bg-primary-600 hover:bg-primary-700 font-semibold rounded shadow"
          >
            Zurück zur Startseite
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default FourOFourError
